import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {errorHandler, httpErrorToString, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {ICard} from 'src/app/interfaces/card';
import {CardApiService} from 'src/app/modules/cards/card-api.service';
import {GetCards} from 'src/app/store/cards/cards.actions';

export interface ICardsState {
    data: ICard[];
    loading: boolean;
    hasValue: boolean;
    error: any;
}

@State<ICardsState>({
    name: 'cards',
    defaults: {
        data: [],
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class CardsState {
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'CardsState'
    };
    constructor(
        private cardsApi: CardApiService,
        private insights: AppInsightsService,
    ) { }

    @Selector()
    public static cards(state: ICardsState): ICard[] {
        return state.data;
    }

    @Selector()
    public static loading(state: ICardsState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: ICardsState): boolean {
        return state.hasValue;
    }

    @Selector()
    public static error(state: ICardsState): any {
        return state.error;
    }

    @Action(GetCards)
    public async getParties(ctx: StateContext<ICardsState>): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            const cards: ICard[] = await firstValueFrom(this.cardsApi.getAll());
            ctx.patchState({
                data: cards,
                hasValue: !!cards?.length,
                loading: false,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
    }
}
