import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ResetForm, UpdateFormValue} from '@ngxs/form-plugin';
import {Navigate} from '@ngxs/router-plugin';
import {firstValueFrom} from 'rxjs';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {errorHandler, httpErrorToString, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';
import {ICard} from 'src/app/interfaces/card';
import {CardApiService} from 'src/app/modules/cards/card-api.service';
import {CreateCard, DeleteCard, EditCard, GetCard, GoToCreateCard, GoToDeleteCard, GoToEditCard, ResetCardForm} from 'src/app/store/cards/card-item/card-item.actions';
import {ICardCreateRequest} from 'src/app/interfaces/card.request';

export interface ICardItemState {
    data: ICard;
    dataForm: {
        model: ICardCreateRequest;
    };
    loading: boolean;
    hasValue: boolean;
    error: any;
}

const EMPTY_CARD: ICardCreateRequest = {
    name: '',
    additionalType: '',
    cardNumber: '',
    additionalProperty: [],
};

@State<ICardItemState>({
    name: 'cardItem',
    defaults: {
        data: null,
        dataForm: {
            model: EMPTY_CARD,
        },
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class CardItemState {
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'CardItemState'
    };
    constructor(
        private cardsApi: CardApiService,
        private store: Store,
        private insights: AppInsightsService,
    ) { }

    @Selector()
    public static card(state: ICardItemState): ICard {
        return state.data;
    }

    @Selector()
    public static loading(state: ICardItemState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: ICardItemState): boolean {
        return state.hasValue;
    }

    @Selector()
    public static error(state: ICardItemState): any {
        return state.error;
    }

    @Action(ResetCardForm)
    public resetCardForm(): void {
        this.store.dispatch(new ResetForm({path: 'cardItem.dataForm'}));
    }

    @Action(GetCard)
    public async getCard(ctx: StateContext<ICardItemState>, {identifier}: GetCard): Promise<ICardItemState> {
        const identifierInState = ctx.getState()?.data?.identifier;
        if (!!identifierInState && identifierInState === identifier) {
            return ctx.getState();
        }
        ctx.patchState({loading: true, error: null});
        try {
            const card: any = await firstValueFrom(this.cardsApi.getById(identifier));
            
            this.store.dispatch(
                new UpdateFormValue({
                    path: 'cardItem.dataForm',
                    value: card,
                })
            );
            ctx.patchState({
                data: card,
                hasValue: !!card,
                loading: false,
                error: null,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                data: null,
                hasValue: false,
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(CreateCard)
    public async createCard(ctx: StateContext<ICardItemState>): Promise<ICardItemState> {
        ctx.patchState({loading: true, error: null});
        try {
            const cardFormModel = ctx.getState().dataForm.model;

            console.log('cardFormModel', cardFormModel);
            

            const card = await firstValueFrom(this.cardsApi.create(cardFormModel));
            ctx.patchState({
                data: card,
                hasValue: !!card,
                loading: false,
                error: null,
            });
            
            this.store.dispatch(new Navigate([`/cards/${card.identifier}`]));

        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                data: null,
                hasValue: false,
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(EditCard)
    public async editCard(ctx: StateContext<ICardItemState>, {identifier}: EditCard): Promise<ICardItemState> {
        ctx.patchState({loading: true, error: null});
        try {
            const cardFormModel = ctx.getState().dataForm.model;
            const updateRequest = {...cardFormModel, identifier};
            const card: ICard = await firstValueFrom(this.cardsApi.update(updateRequest));
            ctx.patchState({
                data: card,
                hasValue: !!card,
                loading: false,
                error: null,
            });

            this.store.dispatch(new Navigate([`/cards/${card.identifier}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                data: null,
                hasValue: false,
                loading: false,
                error: httpErrorToString(error),
            });
        }

        return ctx.getState();
    }

    @Action(DeleteCard)
    public async deleteParty(ctx: StateContext<ICardItemState>, {identifier}: DeleteCard): Promise<ICardItemState> {
        ctx.patchState({loading: true});
        try {
            await firstValueFrom(this.cardsApi.delete(identifier));
            ctx.patchState({data: null});
            this.store.dispatch(
                new ResetForm({
                    path: 'cardItem.dataForm',
                })
            );
            this.store.dispatch(new Navigate([`/cards`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
        return ctx.getState();
    }

    @Action(GoToCreateCard)
    public goToCreateCard(ctx: StateContext<ICardItemState>): void {
        ctx.patchState({data: null, hasValue: false});
        this.store.dispatch(
            new UpdateFormValue({
                path: 'cardItem.dataForm',
                value: EMPTY_CARD,
            })
        );
        this.store.dispatch(new Navigate([`/cards/create`]));
    }
    
    @Action(GoToEditCard)
    public async goToEditCard(ctx: StateContext<ICardItemState>, {identifier}: GoToDeleteCard): Promise<void> {
        const ctxData = ctx.getState().data;
        let card = ctxData;

        if (ctxData?.identifier !== identifier) {
            const tempState = await firstValueFrom(this.store.dispatch(new GetCard(identifier)));
            card = tempState.data;
        }
        this.store.dispatch(
            new UpdateFormValue({
                path: 'cardItem.dataForm',
                value: card,
            })
        );
        this.store.dispatch(new Navigate([`/cards/${identifier}/edit`]));
    }

    @Action(GoToDeleteCard)
    public goToDeleteCard(ctx: StateContext<ICardItemState>, {identifier}: GoToDeleteCard): void {
        this.store.dispatch(new Navigate([`/cards/${identifier}/delete`]));
    }
}
