export class GetCard {
    public static readonly type = '[CardItem] Get card by id';
    constructor(public identifier: string) {}
}

export class CreateCard {
    public static readonly type = '[CardItem] Create card';
    constructor() {}
}

export class EditCard {
    public static readonly type = '[CardItem] Edit card';
    constructor(public identifier: string) {}
}

export class DeleteCard {
    public static readonly type = '[CardItem] Delete party item';
    constructor(public identifier: string) {}
}

export class ResetCardForm {
    public static readonly type = '[CardItem] Reset card form';
}

export class GoToCreateCard {
    public static readonly type = '[CardItem] Go to create card';
}

export class GoToEditCard {
    public static readonly type = '[CardItem] Go to edit card';
    constructor(public identifier: string) {}
}

export class GoToDeleteCard {
    public static readonly type = '[CardItem] Go to delete card';
    constructor(public identifier: string) {}
}