import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {ICard} from 'src/app/interfaces/card';
import {ICardCreateRequest, ICardUpdateRequest} from 'src/app/interfaces/card.request';

@Injectable({
    providedIn: 'root'
})
export class CardApiService {

    constructor(
        private http: HttpClient,
        private config: ConfigurationService,
    ) { }

    public getAll(): Observable<ICard[]> {
        return this.http.get<ICard[]>(`${this.config.get('profileApiUrl')}/cards`);
    }

    public getById(identifier: string): Observable<ICard> {
        return this.http.get<ICard>(`${this.config.get('profileApiUrl')}/cards/${identifier}`);
    }

    public create(card: ICardCreateRequest): Observable<ICard> {
        return this.http.post<ICard>(`${this.config.get('profileApiUrl')}/cards`, card);
    }

    public update(card: ICardUpdateRequest): Observable<ICard> {
        const {identifier} = card;
        return this.http.put<ICard>(`${this.config.get('profileApiUrl')}/cards/${identifier}`, card);
    }

    public delete(identifier: string): Observable<void> {
        return this.http.delete<void>(`${this.config.get('profileApiUrl')}/cards/${identifier}`);
    }
}
